import React, { Fragment } from "react"
import Layout from "../../components/layout"

export default () => (
  <Fragment>
    <Layout>
      <div className="container">
        <h1>This is the portfolio page</h1>
      </div>
    </Layout>
  </Fragment>
)
